import "./App.scss";
import { useState } from "react";

function App() {
  const [date, setDate] = useState(new Date());
  setTimeout(() => {
    setDate(new Date());
  }, 1000);

  return (
    <div className="App">
      <header className="App-header">
        <h1>Hello world</h1>
        <p>{date.toLocaleString()}</p>
      </header>
    </div>
  );
}

export default App;
